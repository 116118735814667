import React from 'react'

import { TypeAnimation } from 'react-type-animation'

import { motion } from 'framer-motion'
import { FcEngineering, FcProcess, FcParallelTasks } from "react-icons/fc";

import { fadeIn } from '../variants';
import { Link } from "react-scroll";
import {BsArrowUpRight} from "react-icons/bs"
const services = [
  {
    name: "Website/Mobile App Development and Maintenance",
    desc: "We specialize in building and maintaining websites that are reliable, secure, and responsive. Our developers will work closely with you to ensure that your website is functional, up-to-date, and compatible with different devices and browsers.",
    link: "",
  },
  {
    name: "Marketing Services",
    desc: "We offer a comprehensive range of marketing services to help promote your brand and increase your online visibility. From search engine optimization (SEO) to social media marketing, we'll develop a customized strategy to drive traffic, generate leads, and boost conversions.",
    link: "",
  },
  {
    name: "E-commerce Solutions",
    desc: "If you're looking to sell products or services online, our e-commerce solutions are perfect for you. We'll set up a secure and user-friendly online store, complete with payment gateways and inventory management, to ensure a seamless and efficient shopping experience for your customers.",
    link: "",
  },
  {
    name: "Web3 Applications:",
    desc: "We specialize in building cutting-edge web3 applications that leverage the power of blockchain technology. Whether you need to develop a decentralized application (DApp) or integrate blockchain functionalities into your existing system, we have the skills and experience to deliver innovative solutions. Our team stays up-to-date with the latest trends and developments in the web3 space, ensuring that your application is built using the most advanced technologies and frameworks..",
    link: "",
  },
];
const Services = () => {
  return (
    <section
      id="services"
      className="section my-44 bg-gradient-to-b from-transparent via-black to-transparent"
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.4)}
            initial="hidden"
            whileInView={"show"}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat   mix-blend-lighten  mb-12 lg:mb-0"
          >
            <h2 className=" text-accent text-3xl font-bold font-primary my-4">
              What we Offer
            </h2>
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-4xl md:text-6xl uppercase my-5 tracking-tighter font-bold font-secondary text-gradient animation-gradient"
            >
              Why we are Unique?
            </motion.h1>
            <Link
              to="work"
              activeClass="active"
              smooth={true}
              spy={true}
              className="btn btn-lg p-4 px-6 cursor-pointer"
            >
              See Our works
            </Link>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.4)}
            initial="hidden"
            whileInView={"show"}
            className="Tetx_Liste flex-1 justify-center text-center"
          >
            <div>
              {services.map((service, index) => {
                const { name, desc, link } = service;
                return (
                  <div
                    className="border-b border-gray-400/20 h-auto mb-4 flex gap-4"
                    key={index}
                  >
                    <div className="max-w-[476px] mb-4">
                      <h4 className="text-sm tracking-wider font-primary font-semibold mb-6 text-primary animate-bounce">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight text-justify">
                        {desc}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>

      <div className="Services"></div>
    </section>
  );
}

export default Services