import React from 'react'

import image from "../assets/gifs/process.gif"
import {FaGithub,FaLinkedin,FaTwitter} from "react-icons/fa"
import { AiFillInstagram, AiFillFacebook, AiFillPhone } from "react-icons/ai";
import { TypeAnimation } from 'react-type-animation'

import { motion } from 'framer-motion'

import { fadeIn } from '../variants';

import cv_fr from "../assets/CV_fr.pdf"
import cv_en from "../assets/CV_en.pdf"
import { Link } from 'react-scroll'
const Banner = () => {
  return (
    <section
      id="home"
      className="section min-h-[85vh] lg:min-h-[78vh] bg-gradient-to-b from-transparent via-black/40 to-transparent "
    >
      <div className="container  mx-auto">
        <div className="md:flex gap-6 items-center">
          <div className="text  text-center lg:text-left">
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-4xl md:text-6xl uppercase tracking-tighter font-bold font-secondary text-gradient bg-gradient-to-r from-accent via-primary to-white"
            >
              Accelerate your path to success We Build your future
            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-3xl lg:text-xl font-primary font-semibold uppercase my-6"
            >
              <span>With us is </span>
              <TypeAnimation
                className="text-accent"
                sequence={["Easy", 2000, "Fast", 2000, "Efficace", 2000]}
                speed={50}
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="max-w-max lg:mx-0 mx-auto lg:w-2/3"
            ></motion.p>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="my-10 flex gap-6 items-center mx-auto lg:mx-0 max-w-max"
            >
              <div>
                <Link
                  className="btn btn-lg flex items-center cursor-pointer"
                  to="contact"
                  activeClass="active"
                  smooth={true}
                  spy={true}
                >
                  Discover us
                </Link>
              </div>

              <div>
                <Link
                  to="contact"
                  activeClass="active"
                  smooth={true}
                  spy={true}
                  className="opacity-70 m-1 cursor-pointer hover:opacity-100"
                >
                  Ask for a Quote
                </Link>
                {/* <div className="flex justify-center items-center">
                  <a
                    href={cv_fr}
                    download={"CV FR KHALIL ZERZOUR"}
                    className="opacity-70 m-1 hover:opacity-100  rounded-lg p-1 w-10 h-6 flex items-center justify-center  pt-2 hover:scale-125 bg-black/20 "
                  >
                    <span className=" text-4xl">🇫🇷 </span>
                  </a>
                  <a
                    href={cv_en}
                    download={"CV EN KHALIL ZERZOUR"}
                    className=" opacity-70 m-1 hover:opacity-100  rounded-lg p-1 w-10 h-6 flex items-center justify-center  pt-2 hover:scale-125 bg-black/20 "
                  >
                    <span className="text-4xl">🇬🇧</span>
                  </a>
                </div> */}
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="social flex items-center gap-6 mx-auto justify-center lg:justify-normal lg:mx-0"
            >
              <a
                href="https://www.instagram.com/fasticom/"
                target="_blank"
                className="hover:scale-150   rounded-full"
              >
                <AiFillInstagram className="text-xl" />
              </a>
              <a
                href="https://www.linkedin.com/in/khalil-zerzour-91a738193/"
                target="_blank"
                className="hover:scale-150   rounded-full"
              >
                <FaLinkedin className="text-xl" />
              </a>
              <a
                href="https://twitter.com/KhalilZerzour"
                target="_blank"
                className="hover:scale-150   rounded-full"
              >
                <AiFillFacebook className="text-xl " />
              </a>
            </motion.div>
          </div>
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="image md:mt-0 mt-24 md:flex max-w[320px] lg:max-w-[600px]"
          >
            <img src={image} alt="" />
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Banner