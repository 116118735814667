import React from 'react'


import { TypeAnimation } from 'react-type-animation'

import { motion } from 'framer-motion'

import { fadeIn } from '../variants';
import { Link } from "react-scroll";
import mint from "../assets/mint.png"
import kolok from "../assets/kolok.png"
import job from "../assets/job.png"
import cowf from "../assets/cowf.png";

const Work = () => {
  return (
    <section id="work" className="section bg-gradient-to-b from-transparent via-black  to-transparent">
      <div className="container mx-auto">
        <div className="">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="md:flex gap-20 mb-10 lg:mb-0"
          >
            {/* text */}
            <div>
              <h2 className=" text-accent text-3xl font-bold font-primary my-4">
                Latest Projects !
              </h2>
              <p className="max-w-auto mb-16">
                <span className="text-xl md:text-3xl uppercase my-5 tracking-tighter font-bold font-secondary text-gradient animation-gradient">
                  At FastiCom
                </span>
                , we take pride in everything we do, as every project is a
                testament to our unwavering commitment to professionalism and a
                deep passion for what we create. Our dedicated team pours their
                expertise and love into each and every task, ensuring that our
                work is not just exceptional, but a true labor of love
              </p>
              {/* <button className="btn btn-sm">View all projects</button> */}
            </div>
            {/* Image */}
            <div className="group my-4 relative overflow-hidden shadow-2xl shadow-black/80 rounded-2xl cursor-pointer">
              <div className="overly group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              <img
                className="group-hover:scale-110 transition-all duration-700"
                src={mint}
                alt=""
              />
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-300 z-50 mb-1">
                <span className="font-primary text-primary text-lg font-bold py-2">
                  Web3 Application
                </span>
              </div>
              <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-3xl text-white ">Mint Dapp</span>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="md:flex space-y-6 md:space-y-0 gap-4 md:mt-16"
          >
            <div className="group relative overflow-hidden shadow-2xl shadow-black/80 rounded-2xl cursor-pointer">
              <div className="overly group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              <img
                className="group-hover:scale-110 transition-all duration-700"
                src={kolok}
                alt=""
              />
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-300 z-50 mb-1">
                <span className="font-primary text-primary text-lg font-bold py-2">
                  Laravel project
                </span>
              </div>
              <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-3xl text-white ">
                  Sharing houses Plateform
                </span>
              </div>
            </div>
            <div className="group relative overflow-hidden shadow-2xl shadow-black/80 rounded-2xl cursor-pointer">
              <div className="overly group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              <img
                className="group-hover:scale-110 transition-all duration-700 opacity-80"
                src={job}
                alt=""
              />
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-300 z-50 mb-1">
                <span className="font-primary text-primary text-lg font-bold py-2">
                  UI/UX Plateform
                </span>
              </div>
              <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-3xl text-white ">Job finder</span>
              </div>
            </div>
            <div className="group relative overflow-hidden shadow-2xl shadow-black/80 rounded-2xl cursor-pointer">
              <div className="overly group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              <img
                className="group-hover:scale-110 transition-all duration-700 opacity-80"
                src={cowf}
                alt=""
              />
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-300 z-50 mb-1">
                <span className="font-primary text-primary text-lg font-bold py-2">
                  Web3 Application
                </span>
              </div>
              <div className="absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-3xl text-white ">Cowfording plateform</span>
              </div>
            </div>
          </motion.div>
        </div>
        
      </div>
    </section>
  );
}

export default Work