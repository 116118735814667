import React, { useRef } from 'react'
import emailjs from "@emailjs/browser";

import { motion } from 'framer-motion'
import logo from "../assets/Fasticom/2.png";

import { fadeIn } from '../variants';

import { ToastContainer, toast } from "react-toastify";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillPhone,
} from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
const Contact = () => {
  const notify = () => toast.success("Message sent 👌🏼");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_x5qo40a",
        "template_epbocm4",
        form.current,
        "GGgLZiPmp-kexpCf1"
      )
      .then(
        (result) => {
          console.log(result.text);
          notify();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section
      id="contact"
      className="section mt-10 bg-gradient-to-t from-black to-transparent"
    >
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.4)}
            initial="hidden"
            whileInView={"show"}
            className="flex-1    mix-blend-lighten  mb-12 lg:mb-0"
          >
            <h2 className=" md:text-left text-center text-accent text-3xl font-bold font-primary my-4">
              Contact US
            </h2>
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="md:text-left text-center  text-4xl md:text-6xl uppercase my-5 tracking-tighter font-bold font-secondary text-gradient animation-gradient"
            >
              Let's Work together !
            </motion.h1>
            <div className="flex items-center md:justify-normal justify-center">
              <div className="text-xl ">
                <p className="font-secondary font-bold text-center md:text-left">
                  Follow Us
                </p>
                <div className="my-4">
                  <div className="flex gap-6 items-center md:justify-normal justify-center">
                    <a
                      href="https://www.instagram.com/fasticom/"
                      target="_blank"
                    >
                      <AiFillInstagram className="hover:scale-150 transition-all rounded-full duration-200 cursor-pointer" />
                    </a>
                    <a
                      href="https://www.instagram.com/fasticom/"
                      target="_blank"
                    >
                      <AiFillFacebook className="hover:scale-150 transition-all rounded-full duration-200 cursor-pointer" />
                    </a>
                    <AiFillPhone className="hover:scale-150 transition-all rounded-full duration-200 cursor-pointer" />
                  </div>

                  <div className="flex mt-6 gap-2 items-center md:justify-normal justify-center font-extralight text-lg">
                    <p className="font-secondary font-bold">
                      Call Us / Whatsapp
                    </p>
                  </div>
                  <div className="md:flex gap-8 my-3">
                    <p className="text-lg flex gap-2 items-center ">
                      <span className="rounded-full  ">🇫🇷</span>{" "}
                      <span>+33 7 51 51 55 60</span>
                    </p>
                    <p className="text-lg flex gap-2 items-center ">
                      <span className="rounded-full  ">🇵🇹</span>{" "}
                      <span>+351 929 187 298</span>
                    </p>
                    <p className="text-lg flex gap-2 items-center ">
                      <span className="rounded-full  ">🇲🇦</span>{" "}
                      <span>+212 6 16 44 35 51</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <form
            className="flex-1 mb-24 md:mb-0"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="flex-1 border border-double  border-gray-400/90 rounded-xl  p-6 flex flex-col gap-8">
              <div className="md:flex gap-2 md:space-y-0 space-y-4">
                <div className="w-full">
                  <input
                    className="w-full  border border-gray-400/90 bg-transparent p-2 px-8 rounded-xl focus:border focus:border-gray-400/90"
                    type="text"
                    placeholder="your name"
                    name="user_name"
                  />
                </div>
                <div className="w-full">
                  <input
                    className="w-full   border border-gray-400/90 bg-transparent p-2 px-8 rounded-xl"
                    type="text"
                    placeholder="your Email"
                    name="user_email"
                  />
                </div>
                <div className="w-full">
                  <input
                    className="w-full   border border-gray-400/90 bg-transparent p-2 px-8 rounded-xl"
                    type="text"
                    placeholder="+33XXXXXX"
                    name="user_tel"
                  />
                </div>
              </div>
              <div className="w-full">
                <textarea
                  rows={2}
                  className="w-full   border border-gray-400/90 bg-transparent p-2 px-8 rounded-xl"
                  type="text"
                  placeholder="Feel free"
                  name="message"
                ></textarea>
              </div>
              <button value={"send"} type="sumbit" className="btn btn-sm">
                Request a Quote
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-center my-8">
          © FastiCom 2023 - All rights reserved.
        </div>
      </div>
    </section>
  );
}

export default Contact