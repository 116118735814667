import React from 'react'

import {BiHomeAlt,biUser} from 'react-icons/bi';
import {BsPersonWorkspace,BsBriefcase,BsChatSquare} from "react-icons/bs"
import {
  AiOutlineQuestionCircle,
  AiOutlineOrderedList,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import wp from "../assets/gifs/wp.gif";

 

import { Link } from 'react-scroll';
const Nav = () => {
  // Numéro de téléphone ou message que vous voulez envoyer sur WhatsApp
  const phoneNumber = "+33751515560"; // Remplacez par le numéro de téléphone ou le message souhaité

  // Générez l'URL WhatsApp avec le numéro de téléphone ou le message
  const whatsappURL = `https://wa.me/${phoneNumber}`;
  return (
    <nav className="fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50">
      <div className="container mx-auto">
        <div className="flex items-center p-8 bg-black/20 w-full h-[100px] backdrop-blur-lg rounded-full max-w-[460px] mx-auto justify-between text-2xl text-white/50 ">
          <Link
            to="home"
            activeClass="active"
            smooth={true}
            spy={true}
            offset={-200}
            className="flex items-center h-8 w-8  md:h-12 md:w-12 justify-center cursor-pointer hover:rounded-full hover:opacity-50"
          >
            <BiHomeAlt />
          </Link>

          <Link
            to="about"
            activeClass="active"
            smooth={true}
            spy={true}
            className="flex items-center h-8 w-8  md:h-12 md:w-12 justify-center cursor-pointer hover:rounded-full hover:opacity-50"
          >
            <AiOutlineQuestionCircle />
          </Link>

          <Link
            to="services"
            className="flex items-center h-8 w-8  md:h-12 md:w-12 justify-center cursor-pointer hover:rounded-full hover:opacity-50"
            activeClass="active"
            smooth={true}
            spy={true}
          >
            <AiOutlineOrderedList />
          </Link>

          <Link
            to="work"
            className="flex items-center h-8 w-8  md:h-12 md:w-12 justify-center cursor-pointer hover:rounded-full hover:opacity-50"
            activeClass="active"
            smooth={true}
            spy={true}
          >
            <BsPersonWorkspace />
          </Link>
          <Link
            to="contact"
            className="flex items-center h-8 w-8  md:h-12 md:w-12 justify-center cursor-pointer hover:rounded-full hover:opacity-50"
            activeClass="active"
            smooth={true}
            spy={true}
          >
            <BsChatSquare />
          </Link>
          <a
            href={whatsappURL}
            className="flex items-center h-8 w-8  md:h-12 md:w-12 justify-center cursor-pointer hover:rounded-full hover:opacity-80 bg-white rounded-full"
          >
            <img src={wp} />
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Nav