import React from 'react'

import CountUp from "react-countup"
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { Link } from 'react-scroll';


const About = () => {
  const [ref,inView]=useInView({
    threshold:0.5
  });
  return (
    <section
      className="section  md:my-10 relative bg-gradient-to-b from-transparent via-black to-transparent"
      id="about"
      ref={ref}
    >
      {/*  <div class="absolute w-16 h-16 bg-red-500 rounded-full -top-4 -left-4"></div>
      <div class="absolute w-16 h-16 bg-black rounded-full -top-4 -right-4"></div>
      <div class="absolute w-16 h-16 bg-black rounded-full top-4 left-4"></div>
      <div class="absolute w-16 h-16 bg-black rounded-full top-4 right-4"></div> */}
      <div className="container mx-auto">
        <div className="flex gap-8 flex-col   lg:flex-row lg:items-center lg:h-screen">
          <motion.div
            variants={fadeIn("right", 0.4)}
            initial="hidden"
            whileInView={"show"}
            className="image flex-1  bg-myimg bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top"
          ></motion.div>

          <motion.div
            variants={fadeIn("left", 0.4)}
            initial="hidden"
            whileInView={"show"}
            className="text flex-1"
          >
            <h2 className=" text-accent text-3xl font-bold font-primary my-4">
              About Us.
            </h2>
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-4xl md:text-6xl uppercase tracking-tighter font-bold font-secondary text-gradient animation-gradient"
            >
              FasTicom Change your life
            </motion.h1>
            <p className="text-md my-3">
              At FastiCom, we believe in accelerating success. We are a dynamic
              and innovative company dedicated to helping individuals and
              businesses reach their full potential in the fastest and most
              efficient way possible. With our unique approach and cutting-edge
              strategies, we aim to revolutionize the way people build their
              future. Our team at FastiCom is composed of highly skilled
              professionals from various backgrounds, including business
              development, technology, marketing, and coaching. We bring
              together diverse expertise to deliver comprehensive solutions
              tailored to the specific needs of our clients.
            </p>
            <div className="md:flex gap-8 grid  items-center mx-auto ">
              <div>
                <div className=" text-center text-primary font-bold text-4xl">
                  +{inView ? <CountUp start={0} end={10} duration={6} /> : "--"}
                </div>
                <div className="text-center tracking-[4px] font-primary">
                  Years of <br />
                  Experience
                </div>
              </div>
              <div>
                <div className="text-center text-primary font-bold text-4xl">
                  +{inView ? <CountUp start={0} end={80} duration={6} /> : "--"}
                </div>
                <div className="text-center tracking-[4px] font-primary">
                  Project <br />
                  Completed
                </div>
              </div>
              <div>
                <div className="text-center text-primary font-bold text-4xl">
                  +
                  {inView ? <CountUp start={0} end={100} duration={6} /> : "--"}{" "}
                </div>
                <div className="text-center tracking-[4px] font-primary">
                  Satisfied <br />
                  Client
                </div>
              </div>
            </div>
            {/* <div>
              <motion.div
                variants={fadeIn("up", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.1 }}
                className="my-10 flex justify-between gap-6 items-center p-2 px-10 mx-auto lg:mx-0 max-w-max"
              >
                <div className="text-2xl shadow-md">🇫🇷</div>
                <div className="text-2xl shadow-md">🇲🇦</div>
                <div className="text-2xl shadow-md">🇺🇸</div>
                <div className="text-2xl shadow-md">🇵🇹 </div>
                <div className="text-2xl shadow-md">🇪🇸</div>
                <div className="text-2xl shadow-md">🇩🇿 </div>
                <div className="text-2xl shadow-md">🇹🇳 </div>
              </motion.div>
            </div> */}
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default About