import React, { useState } from 'react'
import { Link } from 'react-scroll';
import logo from "../assets/Fasticom/2.png"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
const Header = () => {
  const [open, setOpen] = useState(false);

  let links = [
    { name: "Home", link: "home" },
    { name: "Service", link: "services" },
    { name: "About", link: "about" },
  ];
  return (
    <div className="navbar w-full  bg-gradient-to-b from-black to-transparent  ">
      <div className="md:flex items-center md:justify-between justify-center py-4 container mx-auto ">
        {!open && (
          <div className="md:flex  gap-2 items-center font-bold cursor-pointer text-white text-2xl">
            <img src={logo} alt="Logo" srcset="" className="w-32 h-32" />
          </div>
        )}
        <div
          className="text-2xl absolute text-white  right-5 top-5 cursor-pointer md:hidden"
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <span>
              <AiOutlineClose className="text-2xl" />
            </span>
          ) : (
            <span className="">
              <AiOutlineMenu className="text-2xl" />
            </span>
          )}
        </div>
        <ul
          className={`md:flex text-center  md:items-center md:gap-8  pb-8 md:pb-0 transition-all duration-500 ease-in ${
            open ? "" : "hidden"
          }`}
        >
          {links.map((link) => (
            <li key={link.name} className="my-7 md:my-0">
              <Link
                to={link.link}
                activeClass="active"
                smooth={true}
                spy={true}
                className="text-sm cursor-pointer  font-secondary font-bold tracking-widest text-white hover:text-primary duration-500"
              >
                {link.name}
              </Link>
            </li>
          ))}
          <Link
            to="contact"
            activeClass="active"
            smooth={true}
            spy={true}
            className="bg-accent rounded-full px-8 cursor-pointer text-white font-extrabold  font-secondary p-2   hover:bg-primary/75 transition-all duration-300 "
          >
            Request Devis
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Header